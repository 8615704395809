@import 'node_modules/anf-core/dist/1892/1892-framework';

$primaryNavHeight: $desktop-nav-height;

$brandSpecificStyles: (
  'anf': (
    category-link-underline-height: 2px,
    category-list-item-padding-right: 25px,
    logo-font-size: 1.87rem,
    category-link-font-stretch: 100%,
    category-link-letter-spacing: 0.6px,
  ),
  'hol': (
    category-link-underline-height: 3px,
    category-list-item-padding-right: 40px,
    logo-font-size: 1.8rem,
    category-link-font-stretch: 75%,
    category-link-letter-spacing: 0,
    category-link-text-transform: capitalize,
  ),
  'kids': (
    category-link-underline-height: 2px,
    category-list-item-padding-right: 25px,
    logo-font-size: 1.8rem,
    category-link-font-stretch: 100%,
    category-link-letter-spacing: 0.6px,
  ),
  'gh': (
    category-link-underline-height: 3px,
    category-list-item-padding-right: 40px,
    logo-font-size: 1.8rem,
  ),
  'st': (
    category-link-underline-height: 3px,
    category-list-item-padding-right: 40px,
    logo-font-size: 1.8rem,
  )
);

$theme: map-get($brandSpecificStyles, $brand);

.large-screen-nav-container {
  $logo-font-size: map-get($theme, logo-font-size);

  display: none;
  height: $primaryNavHeight;

  @include media-query(1025) {
    display: flex;
  }

  .logo-link {
    align-items: center;
    display: flex;
    margin-left: 20px;
    margin-right: 25px;
    text-decoration: none;
    &:focus {
      outline-offset: 0;
    }
  }

  .logo {
    color: color('primary-dark');
    font-size: $logo-font-size;
    padding-left: 5px;
    padding-right: 25px;
  }

  .custom-logo {
    height: auto;
    max-width: 148px;
    width: 100%;
  }
}

.large-screen-nav-bar {
  $category-list-item-padding-right: map-get($theme, category-list-item-padding-right);
  $category-link-underline-background-color: map-get($theme, category-link-underline-background-color);
  $category-link-underline-height: map-get($theme, category-link-underline-height);

  > ul {
    display: flex;
    flex-wrap: wrap;
    height: 57px;
    list-style: none;
  }

  /*
    You're going to see some legacy class names below ("rs-" something or "nav-" something).
    This is to help content authors' markup (which might still use these class names) 
    still look OK in this new MFE component.  We can work with them to convert their
    classes to our new naming/structure, but these styles are here to help in the meantime!
  */
  // BEGIN STYLES FOR LEGACY CLASS NAMES

  .nav-bar-category-list-item {
    height: 57px;
    line-height: $primaryNavHeight;
    padding-right: $category-list-item-padding-right;
    white-space: nowrap;
    position: relative;

    &.full-width {
      position: static;
    }

    @include media-query(1025) {
      .nav-accordion-items--secondary {
        display: flex;
      }
    }

    .rs-nav-item--minor a.rs-nav-link--minor {
      text-decoration: none;
    }
  }
  // END STYLES FOR LEGACY CLASS NAMES

  .nav-bar-category-link-heading {
    color: color('primary-dark');
    display: inline-block;
    font-size: rem(15);
    font-weight: bold;
    line-height: $primaryNavHeight;
    position: relative; 

    font-stretch: map-get($theme, category-link-font-stretch);
    letter-spacing: map-get($theme, category-link-letter-spacing);
    text-transform: map-get($theme, category-link-text-transform);


    &::after {
      background-color: color('primary-dark');
      bottom: 0;
      content: '';
      height: $category-link-underline-height;
      left: 0;
      opacity: 0;
      position: absolute;
      transition-duration: .15s;
      transition-property: opacity;
      width: 100%;
    }

    &--active::after {
      // Use of !important because we never ever want to lose ownership of this element appearing on hover
      opacity: 1 !important; // sass-lint:disable-line no-important
    }
  }

  .nav-bar-category-link {
    color: color('primary-dark');
    display: inline-block;
    text-decoration: none;
  }

  .nav-bar-category-toggle-button {
    background: none;
    border: none;
    margin-left: 2px;
    padding: 2px;

    &:focus {
      @include focus-indicator();
    }

    &:not(:focus) {
      @include screen-reader-text();
    }
  }

  .flyout-panel-section {
    display: none;
    /**
     * NOTE: It is important that this measurement is in pixels to match the calculations for
     * dynamic positioning in LargeScreenNavBarCategory.jsx
     */
    left: -60px;
    margin-top: -1px; //So that there's no gap between the flyout panel and the nav bar
    position: absolute;

    @include media-query(1025) {
      background: color('primary-light');
    }
    // See the comment about legacy classnames earlier in this file... here's another block!
    // BEGIN STYLES FOR LEGACY CLASS NAMES

    .rs-cat-nav__header {
      @include media-query(1025) {
        line-height: 1.4;
        margin-bottom: 22px;
      }

      a {
        text-decoration: none;
      }
    }

    @include media-query(1025) {
      .nav-accordion-container--level2 .rs-nav-item--minor {
        line-height: 1.4;
        margin-bottom: 13px;
      }

      .rs-nav-item--minor > .rs-nav-link--minor {

       &:after {
          bottom: -3px;
          content: '';
          height: 2px;
          left: 0;
          position: absolute;
          transition: all .25s linear;
          width: 100%;
        }

        &:hover:after,
        &:focus:after {
          background: $category-link-underline-background-color;
        }
      }
    }
    // END STYLES FOR LEGACY CLASS NAMES
  }

  .nav-bar-category-toggle-button[aria-expanded="true"] + .flyout-panel-section {
    display: block;
  }

  .full-width .flyout-panel-section {
    left: 0;
    right: 0;

    .nav-flyout-list {
      display: flex;
      flex-direction: column;
      & > li {
        min-width: 180px;
      }
      // only split categories into columns if we know they'll fit
      @include media-query(1200) {
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(8, auto);
      }
    }
  }

  .full-width .nav-flyout-panel {
    justify-content: space-between;
    // don't let espot content squish our actal nav
    flex-shrink: 0;
    & > div + div {
      border-left: 1px solid color('tertiary-light');
      padding-left: 40px;
      @if $brand == hol {
        // no dividing lines for hollister's different category structure
        border-left: none;
        padding-left: 0;
      }
    }
  }

}
