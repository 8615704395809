/**
 * Styles for RailNavigation and RailNavigation.* React components
 *
 * These styles are designed to be used on top of crs-app styles.
 * BEM syntax is used for selectors (see https://getbem.com/).
 */

@use "sass:math";

 // Styles are for h1
 .mfe-rail-navigation__heading.h2 {
    margin-bottom: 2.5rem;
}

.mfe-rail-navigation__heading-link {
  @if $brand-family == anf {
    font-family: font-family('garamond');
  }

  text-decoration: none;
}

// Styles are for h3; use instance-specific selectors and styles for other heading elements
.mfe-rail-navigation__list-heading {
  $body-type-style-map: type-style('body', $brand: $brand);
  $h2-body-type-style-map: type-style('h2', $brand: $brand);
  $body-font-size: map-get($body-type-style-map, 'font-size');
  $body-line-height: map-get($body-type-style-map, 'line-height');

  color: color('heading-text-color', $brand: $brand);
  font-family: map-get($h2-body-type-style-map, 'font-family');
  font-size: rem($body-font-size);
  font-stretch: map-get($h2-body-type-style-map, 'font-stretch');
  font-weight: map-get($h2-body-type-style-map, 'font-weight');
  letter-spacing: map-get($h2-body-type-style-map, 'letter-spacing');
  line-height: if($body-line-height and $body-font-size, math.div($body-line-height, $body-font-size), null);
  text-transform: map-get($h2-body-type-style-map, 'text-transform');

  // Brand-specific exceptions
  @if $brand-family == anf {
    font-family: font-family('garamond');
  }

  @if $brand == anf {
    text-transform: capitalize;
  }

  @if $brand == kids {
    text-transform: lowercase;
  }
}

.mfe-rail-navigation__list {
  margin-bottom: 3.5rem;
}

.mfe-rail-navigation__list-item {
  list-style-type: none;
  margin: 1.5rem 0;
}

.mfe-rail-navigation__list-item-link {
  display: block;
  text-decoration: none;

  &--current {
    font-weight: bold;

    // TODO: Do we really not want to inherit font family for each brand?
    @if $brand-family == anf {
      font-family: font-family('trade-gothic');
    }

    // TODO: Do we really not want to inherit font family for each brand?
    @if $brand-family == hol {
      font-family: font-family('source-sans-pro');
    }
  }
}

// Match crs-app design system parent selector and anf-core selectors to beat their specificity
.mfe-rail-navigation__list-item-disclosure {
  .scope-1892 & {
    .disclosure {
      .label-and-button-container {
        width: 100%;
      }

      button {
        text-align: left;
        text-transform: none;
        width: 100%;

        .disclosure-label {
          margin-right: 0;
        }
      }
    }
  }
}

.mfe-rail-navigation__sub-list-item {
  list-style-type: none;
  margin: 1.5rem 0;
}

.mfe-rail-navigation__sub-list-item-link {
  display: block;
  padding-left: 2.3rem;
  text-decoration: none;

  &--current {
    font-weight: bold;

    // TODO: Do we really not want to inherit font family for each brand?
    @if $brand-family == anf {
      font-family: font-family('trade-gothic');
    }

    // TODO: Do we really not want to inherit font family for each brand?
    @if $brand-family == hol {
      font-family: font-family('source-sans-pro');
    }
  }
}