@import 'node_modules/anf-core/dist/1892/1892-framework';
/*
 * Variables used across components
 */

// Brand tabs
$brand-tabs-height: 50px;

// Small screen nav
$l1AccordionHorizontalPadding: rem(20);

// Mobile nav
$mobile-nav-height: 55px;

// Desktop nav
$desktop-nav-height: 75px;

// Product grid
$product-grid-padding-horizontal: 5px;
$product-grid-padding-right-lg: 16px;
$product-grid-row-gap: 15px;
$product-grid-column-gap: 6px;
$product-grid-column-gap-md: 16px;

// Product Card
$product-card-elevated-whitespace: 20px;
$product-card-elevated-blur-radius: 12px;

// Aspect ratio standard is 4/5
$product-img-aspect-ratio: 0.8;

// z-index values
$header-backdrop-z-index: 999; // 1 less than header and brand tabs
$header-z-index: 1000; // 1 more than header backdrop
$small-nav-z-index: 1000; // 1 less than the modal so the nav is behind it
$skip-link-z-index: 1001; // 1 more than header so that it is not cut off
$modal-z-index: 1001; // 1 more than header and brand tabs

// Brand families
$brand-family: null;

@if $brand ==anf or $brand ==kids {
  $brand-family: anf
}

@if $brand ==hol or $brand ==gh or $brand ==st {
  $brand-family: hol
}

$desktop-grid-columns: 3;
$mobile-grid-columns: 2;

$site-width: 1568px;